.main-bg {
    background-color: #eff9ff;
    width: 100%;
    height: 100vh;
  }
  
  
  .hero-image {
    width: 80%;
    margin: 11% 10%;
    object-fit: contain;
  }
  
  /* .sign-up {
    margin-top: 12%;
  } */

  .my-otp-forgot-password{
    margin-left: -7% !important
  }
  
  .stay-safe {
    margin-left: 6%;
  }
  
  .sign-up-title {
    margin-left: 6%;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }

  .otp-sent-email-forgot-password{
    font-size: 15px;
    margin-left: 0%;
  }

  .sign-up-title-forgot-password{
    margin-left: 0%;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }
  
  .link-button {
    color: #f5a281;
  }
  
  .input-fields {
    margin-left: 1%;
    margin-top: 3%;
  }
  
  .text-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    font-size: small !important;
  }

.forgot-captcha{
  margin-top: 6%;
  margin-left: 4%;
  font-size: 14px;
}

.reset-captcha{
  margin-top: 6%;
  margin-left: 0%;
  font-size: 14px;
}
  
  .error {
    color: red;
  }
  .form-group .error {
    right: calc(100% - 100%);
    position: absolute;
    top: 10%;
  }
  
  .form-group {
    position: relative;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: center;
    width: auto;
  }
  
  .button-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #ffffff;
    background-color: #78aac3;
  }
  

  
  .selection {
    margin-left: 4%;
  }
  
  .small-avatar {
    right: calc(100% - 100%);
  }
  
  .selection-text {
    color: #666666;
    margin-left: 7%;
  }
  
  .radio {
    /* width: 20px; */
    height: 20px;
    flex-grow: 0;
    margin: 8px 12px 8px 0;
    object-fit: contain;
    background-color: #f5a281;
  }
  
  .captcha {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
  }
  
  .link-text {
    color: #3388ff;
    cursor: pointer;
  }
  
  .checkbox {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
    color: #666666;
  }
  
  .pg-bar2 .progress-bar {
    background-color: #78aac3;
    color: white;
    height: 7px;
  }
  
  .pg-bar2 {
    height: 7px;
  }
  
  .pg-bar {
    margin-left: 4%;
  }
  
  .pg-title {
    padding-left: 0px;
  }
  
  .image-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    width: 100%;
    height: 180px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .dialog {
    width: 450px;
    height: 650px;
    margin-top: -100%;
    z-index: 10;
    border-color: transparent;
    border-radius: 5px;
  }
  
  .image {
    max-width: 400px;
    object-fit: cover;
    max-height: 600px;
  }
  
  .close-btn{
    float: right;
  }