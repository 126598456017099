header {
  padding: 50px 0;
  align-items: center;
  display: flex;
  h2 {
    margin: 0;
  }
  .location {
    margin-left: 25px;
    font-weight: 600;
    font-size: 16px;
    color: #666;
  }
  .profile {
    img {
      width: 35px;
      border-radius: 3px;
    }
    p {
      padding: 0 10px;
      font-size: 16px;
      margin: 0;
    }
    .options {
      img {
        width: 20px;
      }
    }
  }
}
