.otp-input {
  display: flex;
}

.otp-input input {
  margin: 5px;
  width: 15%;
}

.my-otp input {
  padding: 0 !important;
  width: 100% !important;
  margin: 5px;
}

.form-section {
  padding: 0 10% !important;
}
.otp-sent-email {
  word-break: break-all;
}

@media screen and (max-width: 1045px) {
  .sign-up-title {
    font-size: 40px;
  }
  .flex-center p {
    font-size: 15px;
  }
}
@media screen and (min-width: 768px) {
  .otp-sent-email {
    margin: 6% !important;
  }  
}

@media screen and (max-width: 767px) {
  .otp-form {
    display: inline-flex;
  }
  .form-section {
    padding: 0 20% !important;
  }
  .sign-up-title {
    font-size: 25px;
  }
  .otp-sent-email {
    margin: 0 !important;
  }
}
@media screen and (min-width: 850px) and (max-width: 1045px) {
  .sign-up-title {
    font-size: 30px;
  }
  .flex-center p {
    font-size: 12px;
  }
  .page-image .hero-image {
   width: 70%; 
  }
  .captcha {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 800px) and (max-width: 850px) {
  .sign-up-title {
    font-size: 27px;
  }
}

@media screen and (min-width: 768px) and (max-width: 799px) {
  .sign-up-title {
    font-size: 27px;
  }
  .flex-center p {
    font-size: 10px;
  }
  .submit-btn {
    font-size: 16px;
  }
  .captcha {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 700px) {
  .page-image .hero-image {
    width: 85%;
  }
}

@media screen and (min-width: 419px) and (max-width: 600px) {
  .page-image .hero-image {
    width: 80%;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1150px) {
  .sign-up-title {
    font-size: 38px;
  }
}
@media screen and (min-width: 361px) and (max-width: 418px) {
  .flex-center p {
    font-size: 12px;
  }
  .page-image .hero-image {
    width: 75%;
  }
}

@media screen and (min-width: 310px) and (max-width: 360px) {
  .sign-up-title {
    font-size: 22px;
  }
  .flex-center p {
    font-size: 10px;
  }
  .flex-center a {
    font-size: 12px;
  }
  .captcha {
    font-size: 10px !important;
  }
  .page-image .hero-image {
    width: 70%;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px){
  .submit-btn {
    font-size: 13px;
    padding: 10px;
  }
}

