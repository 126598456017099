.main-bg {
  background-color: #eff9ff;
  width: 100%;
  height: 100vh;
}
.btn-gry {
  background-color: gray;
}

.hero-title {
  /* margin-top: 6%;
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3; */
}

.hero-image {
  width: 70%;
  margin: 11% 10%;
  object-fit: contain;
}

/* .sign-up {
  margin-top: 12%;
} */

.stay-safe {
  margin-left: 6%;
}

.sign-up-title {
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3;
}

.link-button {
  color: #f5a281;
}

.input-fields {
  margin-left: 1%;
  margin-top: 3%;
}

.text-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #666666;
}

.error {
  color: red;
}

.form-group .error {
  position: absolute;
  top: 16px;
  right: 20px;
}

.ok-box{
  width: 66px;
  height: 35px;
  flex-grow: 0;
  padding: 8px 22px 7px;
  border-radius: 12px;
  background-color: #78aac3;
  color: white;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.ok-view{
  position: sticky;
  margin-left: -10px;
  margin-top: -12.5%;
  /* cursor: pointer; */
}



.form-group {
  position: relative;
}

.form-inline .form-control {
  display: inline-block;
  vertical-align: center;
  width: auto;
}

.button-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #ffffff;
  background-color: #78aac3;
}

.recipient-tab1 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}
.recipient-tab2 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}

.selection {
  margin-left: 4%;
}

.small-avatar {
  right: calc(100% - 100%);
}

.selection-text {
  color: #666666;
  margin-left: 7%;
}

.radio {
  /* width: 20px; */
  height: 20px;
  flex-grow: 0;
  margin: 8px 12px 8px 0;
  object-fit: contain;
  background-color: #f5a281;
}

.captcha {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
}

.link-text {
  color: #3388ff;
  cursor: pointer;
}

.checkbox {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
  color: #666666;
}

.pg-bar2 .progress-bar {
  background-color: #78aac3;
  color: white;
  height: 7px;
}

.pg-bar2 {
  height: 7px;
}

.pg-bar {
  margin-left: 4%;
}

.pg-title {
  padding-left: 0px;
}

.submission-image {
  width: 65%;
  margin-top: 20%;
  margin-left: 15%;
}

.image1 {
  margin-left: 30%;
  margin-top: 15%;
  width: 8%;
}

.image-input{
  margin-left: 0% !important;
}

.submission-text-group {
  margin-right: 27%;
  font-size: 45px;
  font-weight: 500;
}

.submission-text1 {
  margin-top: 1%;
  color: #f5a281;
  /* margin-left: 5%; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.submission-text2 {
  /* margin-top: 1%; */
  color: #f5a281;
  /* margin-left: -8%; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.submission-text3 {
  /* margin-top: 1%; */
  color: #f5a281;
  /* margin-left: -10%; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.dashboard-btn {
  margin-left: 19%;
  margin-top: 2%;
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
  text-align: center;
  height: auto;
}
.dashboard-btn:hover {
  color: #ffffff;
}

.navigation{
  margin-top: 2%;
  margin-bottom: 2%;
}
.survey-main{
  margin-bottom: 2%;
}

.fileUpload{
  margin : 0px auto !important;
}
.btn-wrapper {
  margin-top: 80px;
}
.btn-wrapper a {
  width: 100%;
  padding: 16px 63px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media screen and (max-width: 768px) {
  .submission-text-group {
    margin-right: 0px;
    font-size: 25px;
    font-weight: 500;
    padding-left: 70px;
  }

  .image1 {
    margin-left: 50%;
    margin-top: 5%;
    width: 8%;
  }

  .dashboard-btn {
    margin-left: 39%;
    margin-top: -9%;
    height: auto;
  }
  .image-preview-modal {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image-preview-modal button {
    position: absolute;
    top: 0;
    right: 20px;
    background: white;
    border-radius: 100%;
    width: 30px;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1460px) {
  .dashboard-btn {
    margin-left: 15%;
  }
  .submission-text-group {
    font-size: 35px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1149px) {
  .dashboard-btn {
    margin-left: 10%;
  }
  .submission-text-group {
    font-size: 30px;
  }
}

@media screen and (min-width: 838px) and (max-width: 991px) {
  .dashboard-btn {
    margin-left: 7%;
  }

  .submission-text-group {
    font-size: 25px;
  }
}

@media screen and (min-width: 769px) and (max-width: 837px) {
  .dashboard-btn {
    margin-left: 4%;
  }
  .submission-text-group {
    font-size: 22px;
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .dashboard-btn {
    margin-left: 22%;
  }
}
@media screen and (min-width: 700px) and (max-width: 767px) {
  .dashboard-btn {
    margin-left: 40%;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-btn {
    margin-left: 30%;
  }
}

@media screen and (min-width: 501px) and (max-width: 599px) {
  .dashboard-btn {
    margin-left: 34%;
  }
}

@media screen and (max-width: 499px) {
  .dashboard-btn {
    margin-left: 22%;
  }

  .submission-text-group {
    font-size: 20px;
  } 
}

@media screen and (max-width: 350px) {
  .dashboard-btn {
    margin-left: 10%;
  }

  .submission-text-group {
    font-size: 17px;
  } 
}

@media screen and (max-width: 300px) {
  .dashboard-btn {
    margin: 0;
  }

  .submission-text-group {
    font-size: 20px;
    padding: 0;
  } 
}