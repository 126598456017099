.form-wrapper {
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  .image-box {
    max-width: 50%;
    text-align: left;
    img {
      width: 100%;
    }
  }
  .form-box {
    max-width: 50%;
    width: 550px;
    p {
      margin: 0;
    }
    .link {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .bottom-sec {
    max-width: 550px;
    margin-left: auto;
  }
}
