.button {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  min-height: 32px;
  border: none;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 10px 17px;
  white-space: nowrap;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  @include mq("phone-m") {
    padding: 6px 15px;
  }
  @include mq("phone-s") {
    font-size: 1rem;
    padding: 6px 10px;
  }
}

.button-primary {
  background-color: #78aac3;
  color: #fff;
  &:hover {
    background-color: #78aac3;
  }
  &:active {
    background-color: #78aac3;
  }
  &:focus {
    background-color: #78aac3;
  }
  &:disabled {
    opacity: 0.5;
  }
  .button-primary:focus,
  .button-primary.focus {
    background-color: #78aac3;
  }
}
.button-primary:not(:disabled):not(.disabled):active {
  background-color: #78aac3;
}
