.main-bg {
    background-color: #eff9ff;
    width: 100%;
    height: 100vh;
  }

  .password-view{
    position: sticky;
    margin-left: 92%;
    margin-top: -16%;
    cursor: pointer;
  }
  
  .hero-title {
    margin-top: 4%;
    margin-left: 3%;
 
  }
  .hero-title h4{
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }
  .hero-image {
    width: 80%;
    margin: 11% 10%;
    object-fit: contain;
  }
  
  .sign-up {
    padding: 20px 0;
  }
  
  .stay-safe {
    margin-left: 6%;
  }
  .page-image{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sign-up-title {
    margin-left: 6%;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }
  
  .link-button {
    color: #f5a281;
  }
  
  .input-fields {
    margin-left: 1%;
    margin-top: 3%;
  }
  
  .text-input {
    /* border-color: #ffffff !important; */
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    font-size: small !important;
  }
  
  .error {
    color: red;
  }
  .form-group .error {
    position: absolute;
    top: 46px !important;
    right: 0px !important;
  }
  
  
  .form-group {
    position: relative;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: center;
    width: auto;
  }
  
  .button-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #ffffff;
    background-color: #78aac3;
  }
  
  .recipient-tab1 {
    /* width: 26%; */
    /* height: 37px; */
    /* flex-grow: 0; */
   
    /* margin-left: 2%; */
  }
  .recipient-content{
    padding: 0px 12px;
    border-radius: 12px;
    background-color: #ffffff;
    margin-left: 2%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .recipient-content .name{
    white-space: nowrap;
  }
  .recipient-tab2 {
    /* width: 26%; */
    /* height: 37px;
    flex-grow: 0; */
    /* padding: 0px 12px;
    border-radius: 12px;
    background-color: #ffffff; */
    /* margin-left: 2%; */
  }
  
  .selection {
    margin-left: 4%;
    width: 100%;
  }
  .new-divs{
    width: 78%;
    margin-top: 10px;
  }
  
  .small-avatar {
    right: calc(100% - 100%);
  }
  
  .selection-text {
    color: #666666;
    margin-left: 7%;
  }
  
  .radio {
    /* width: 20px; */
    height: 20px;
    flex-grow: 0;
    margin: 8px 12px 8px 0;
    object-fit: contain;
    background-color: #f5a281;
  }
  
  .captcha {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
  }
  
  .link-text {
    color: #3388ff;
    cursor: pointer;
  }
  
  .checkbox {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
    color: #666666;
  }
  
  .pg-bar2 .progress-bar {
    background-color: #78aac3;
    color: white;
    height: 7px;
  }
  
  .pg-bar2 {
    height: 7px;
  }
  
  .pg-bar {
    margin-left: 4%;
  }
  
  .pg-title {
    padding-left: 0px;
  }
  .forget-pass-btns{
    margin-left: 20px;
  }
  .for-customers{
    width: 60%;
    margin-left: 20px;
  }
  
  .image-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    width: 100%;
    height: 180px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .dialog {
    width: 450px;
    height: 650px;
    margin-top: -100%;
    z-index: 10;
    border-color: transparent;
    border-radius: 5px;
  }
  
  .image {
    max-width: 400px;
    object-fit: cover;
    max-height: 600px;
  }
  
  .close-btn{
    float: right;
  }


  @media only screen and (max-width: 767px) {
    .main-bg .hero-title{
      text-align: center;
    }
    .main-bg .sign-up{
      text-align: center;
    }
    .main-bg .sign-up .stay-safe,
    .main-bg .sign-up .sign-up-title,
    .main-bg form .form-group .text-input,
    .main-bg form button,
    .main-bg .sign-up .captcha,
    form .form-group .image-input-register,
    form .checkbox{
      margin-left: 0;
    }
    .hero-title h4,
    .sign-up-title{
      font-size: 32px;
    }
    .selection{
      width: 100%;
    }
  
  }

  @media only screen and (max-width: 991px) {
    .page-image .hero-image{
      width: 90%;
    }
  
  }
  @media only screen and (max-width: 767px) {
 
    .for-customers{
      margin: auto;
    }
    .for-customers .selection{
      margin-left: 0;
    }
    .selection-text{
      margin-left: 0;
    }
  }