@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap);
.main-bg {
    background-color: #eff9ff;
    width: 100%;
    height: 100vh;
  }

  .password-view{
    position: sticky;
    margin-left: 92%;
    margin-top: -16%;
    cursor: pointer;
  }
  
  .hero-title {
    margin-top: 4%;
    margin-left: 3%;
 
  }
  .hero-title h4{
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }
  .hero-image {
    width: 80%;
    margin: 11% 10%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  
  .sign-up {
    padding: 20px 0;
  }
  
  .stay-safe {
    margin-left: 6%;
  }
  .page-image{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sign-up-title {
    margin-left: 6%;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }
  
  .link-button {
    color: #f5a281;
  }
  
  .input-fields {
    margin-left: 1%;
    margin-top: 3%;
  }
  
  .text-input {
    /* border-color: #ffffff !important; */
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    font-size: small !important;
  }
  
  .error {
    color: red;
  }
  .form-group .error {
    position: absolute;
    top: 46px !important;
    right: 0px !important;
  }
  
  
  .form-group {
    position: relative;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: center;
    width: auto;
  }
  
  .button-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #ffffff;
    background-color: #78aac3;
  }
  
  .recipient-tab1 {
    /* width: 26%; */
    /* height: 37px; */
    /* flex-grow: 0; */
   
    /* margin-left: 2%; */
  }
  .recipient-content{
    padding: 0px 12px;
    border-radius: 12px;
    background-color: #ffffff;
    margin-left: 2%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .recipient-content .name{
    white-space: nowrap;
  }
  .recipient-tab2 {
    /* width: 26%; */
    /* height: 37px;
    flex-grow: 0; */
    /* padding: 0px 12px;
    border-radius: 12px;
    background-color: #ffffff; */
    /* margin-left: 2%; */
  }
  
  .selection {
    margin-left: 4%;
    width: 100%;
  }
  .new-divs{
    width: 78%;
    margin-top: 10px;
  }
  
  .small-avatar {
    right: calc(100% - 100%);
  }
  
  .selection-text {
    color: #666666;
    margin-left: 7%;
  }
  
  .radio {
    /* width: 20px; */
    height: 20px;
    flex-grow: 0;
    margin: 8px 12px 8px 0;
    -o-object-fit: contain;
       object-fit: contain;
    background-color: #f5a281;
  }
  
  .captcha {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
  }
  
  .link-text {
    color: #3388ff;
    cursor: pointer;
  }
  
  .checkbox {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
    color: #666666;
  }
  
  .pg-bar2 .progress-bar {
    background-color: #78aac3;
    color: white;
    height: 7px;
  }
  
  .pg-bar2 {
    height: 7px;
  }
  
  .pg-bar {
    margin-left: 4%;
  }
  
  .pg-title {
    padding-left: 0px;
  }
  .forget-pass-btns{
    margin-left: 20px;
  }
  .for-customers{
    width: 60%;
    margin-left: 20px;
  }
  
  .image-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    width: 100%;
    height: 180px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .dialog {
    width: 450px;
    height: 650px;
    margin-top: -100%;
    z-index: 10;
    border-color: transparent;
    border-radius: 5px;
  }
  
  .image {
    max-width: 400px;
    -o-object-fit: cover;
       object-fit: cover;
    max-height: 600px;
  }
  
  .close-btn{
    float: right;
  }


  @media only screen and (max-width: 767px) {
    .main-bg .hero-title{
      text-align: center;
    }
    .main-bg .sign-up{
      text-align: center;
    }
    .main-bg .sign-up .stay-safe,
    .main-bg .sign-up .sign-up-title,
    .main-bg form .form-group .text-input,
    .main-bg form button,
    .main-bg .sign-up .captcha,
    form .form-group .image-input-register,
    form .checkbox{
      margin-left: 0;
    }
    .hero-title h4,
    .sign-up-title{
      font-size: 32px;
    }
    .selection{
      width: 100%;
    }
  
  }

  @media only screen and (max-width: 991px) {
    .page-image .hero-image{
      width: 90%;
    }
  
  }
  @media only screen and (max-width: 767px) {
 
    .for-customers{
      margin: auto;
    }
    .for-customers .selection{
      margin-left: 0;
    }
    .selection-text{
      margin-left: 0;
    }
  }
.main-bg {
    background-color: #eff9ff;
    width: 100%;
    height: 100vh;
  }
  
  
  .hero-image {
    width: 80%;
    margin: 11% 10%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  
  /* .sign-up {
    margin-top: 12%;
  } */

  .my-otp-forgot-password{
    margin-left: -7% !important
  }
  
  .stay-safe {
    margin-left: 6%;
  }
  
  .sign-up-title {
    margin-left: 6%;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }

  .otp-sent-email-forgot-password{
    font-size: 15px;
    margin-left: 0%;
  }

  .sign-up-title-forgot-password{
    margin-left: 0%;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #78aac3;
  }
  
  .link-button {
    color: #f5a281;
  }
  
  .input-fields {
    margin-left: 1%;
    margin-top: 3%;
  }
  
  .text-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    font-size: small !important;
  }

.forgot-captcha{
  margin-top: 6%;
  margin-left: 4%;
  font-size: 14px;
}

.reset-captcha{
  margin-top: 6%;
  margin-left: 0%;
  font-size: 14px;
}
  
  .error {
    color: red;
  }
  .form-group .error {
    right: calc(100% - 100%);
    position: absolute;
    top: 10%;
  }
  
  .form-group {
    position: relative;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: center;
    width: auto;
  }
  
  .button-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #ffffff;
    background-color: #78aac3;
  }
  

  
  .selection {
    margin-left: 4%;
  }
  
  .small-avatar {
    right: calc(100% - 100%);
  }
  
  .selection-text {
    color: #666666;
    margin-left: 7%;
  }
  
  .radio {
    /* width: 20px; */
    height: 20px;
    flex-grow: 0;
    margin: 8px 12px 8px 0;
    -o-object-fit: contain;
       object-fit: contain;
    background-color: #f5a281;
  }
  
  .captcha {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
  }
  
  .link-text {
    color: #3388ff;
    cursor: pointer;
  }
  
  .checkbox {
    margin-top: 6%;
    margin-left: 6%;
    font-size: 14px;
    color: #666666;
  }
  
  .pg-bar2 .progress-bar {
    background-color: #78aac3;
    color: white;
    height: 7px;
  }
  
  .pg-bar2 {
    height: 7px;
  }
  
  .pg-bar {
    margin-left: 4%;
  }
  
  .pg-title {
    padding-left: 0px;
  }
  
  .image-input {
    border-color: #ffffff;
    border-radius: 12px;
    margin: 5%;
    color: #666666;
    width: 100%;
    height: 180px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .dialog {
    width: 450px;
    height: 650px;
    margin-top: -100%;
    z-index: 10;
    border-color: transparent;
    border-radius: 5px;
  }
  
  .image {
    max-width: 400px;
    -o-object-fit: cover;
       object-fit: cover;
    max-height: 600px;
  }
  
  .close-btn{
    float: right;
  }
.main-bg {
  background-color: #eff9ff;
  width: 100%;
  height: 100vh;
}

.login-row{
  display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px; */
    margin-left: -15px;
}

.my-otp{
  display: flex;
  width: 50%;
  margin-left: 5%;
}

.file-error{
  color: red;
  margin-left: 6%;
}

.input-fields2{
  margin-top: -10px;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #f5a281 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #f5a281;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #f5a281 ;
}
.image-upload-text{
  margin-left: 18%;
}

.image-upload-text2{
  margin-left: -3%;
  margin-top: 1%;
}
.image-upload-text3{
  margin-left: 22%;
  margin-top: 1%;
}

.locateme-view{
  position: sticky;
  margin-left: 76%;
  margin-top: -13%;
  cursor: pointer
}
.locateme-view1{
  position: sticky;
  margin-top: -6%;
  text-align: end;
  color: #666666b5;
}

.hero-title {
  /* margin-top: 6%;
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3; */
}

.hero-image {
  width: 80%;
  margin: 11% 10%;
  -o-object-fit: contain;
     object-fit: contain;
}

/* .sign-up {
  margin-top: 12%;
} */

.stay-safe {
  margin-left: 6%;
}

.sign-up-title {
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3;
}

.link-button {
  color: #f5a281;
  margin-top: -4px;
}

.input-fields {
  margin-left: 1%;
  margin-top: 3%;
}

.password-view1{
  position: sticky;
  margin-left: 92%;
  margin-top: -15%;
  cursor: pointer;
}

.text-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #666666;
}

.error {
  color: red;
}
.form-group .error {
  position: absolute;
  top: 16px;
  right: 20px;
}

.form-group {
  position: relative;
}
.form-inline .form-control {
  display: inline-block;
  vertical-align: center;
  width: auto;
}

.button-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #ffffff;
  background-color: #78aac3;
}

.recipient-tab1 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}
.recipient-tab2 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}

.selection {
  margin-left: 4%;
}

.small-avatar {
  right: calc(100% - 100%);
}

.selection-text {
  color: #666666;
  margin-left: 7%;
}

.radio-btn {
  /* width: 20px; */
  margin-right: 4%;
  background-color: #f5a281;
}

.captcha {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
}

.link-text {
  color: #3388ff;
  cursor: pointer;
}

.checkbox {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
  color: #666666;
}

.pg-bar2 .progress-bar {
  background-color: #78aac3;
  color: white;
  height: 7px;
}

.pg-bar2 {
  height: 7px;
}

.pg-bar {
  margin-left: 4%;
}

.pg-title {
  padding-left: 0px;
}

.image-input-register {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 15px;
  color: #666666;
  width: 100%;
  height: 220px;
  text-align: center;
  background-color: #ffffff;
  margin-left:5%;

}
.dropzone {
  height: 160px;
}

.dialog {
  width: 450px;
  height: 650px;
  margin-top: -100%;
  z-index: 10;
  border-color: transparent;
  border-radius: 5px;
}

.image {
  max-width: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 600px;
}

.close-btn {
  float: right;
}


.error-parent {
  margin-top: 2%;
  margin-left: 6%;
  text-align: justify;
}

.custom-error {
  color: red;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .error-parent {
    margin: 0;
  }
  .image-preview-modal {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image-preview-modal .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border-radius: 100%;
    width: 30px;
  }
  .image-preview-modal .image {
    max-width: 100% !important;
  }
}
/* .main-bg {
  background-color: #eff9ff;
  width: 100%;
  height: 100vh;
} */

/* .hero-image {
  width: 80%;
  margin: 11% 10%;
  object-fit: contain;
} */

.user-icon{
  width: 25px;
  height: 25px;
  margin: 0px 1px -7px 0px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 3px;
}

.main-panel {
  /* height: 70%; */
  /* margin: 303.3px 87px 0 719px; */
  margin-top: 8%;
  padding: 30px;
  border-radius: 20px;
  background-color: #c4deee;
}

.box-1 {
  height: 180px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
}
.box-2 {
  margin-top: 5%;
  height: 430px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px;
}

.box-3 {
  width: 120%;
  height: 220px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
}
.box-4 {
  width: 120%;
  margin-top: 17%;
  height: 316px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
}

.text1-main {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 5%;
  padding-left: 5%;
}
.text1-sub {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 1%;
  padding-bottom: 2%;
}
.text1-sub1 {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 6%;
  padding-bottom: 2%;
}
.text2-main {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 5%;
  padding-left: 2%;
  padding-bottom: 1%;
}
.text2-sub1 {
  /* font-size: 18px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-top: 5%;
  padding-left: 2%;
}

.text2-sub2 {
  /* font-size: 20px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f5a281;
  padding-top: 5%;
  padding-left: 5%;
}

.text4-main {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 5%;
  padding-left: 5%;
}

.text4-sub {
  width: 80%;
  height: 50px;
  flex-grow: 0;
  margin: 16px 0px 30px 34px;
  /* font-size: 18px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.view-more {
  width: 154px;
  height: 43px;
  flex-grow: 0;
  margin-left: 21%;
  padding: 11px 32px;
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
}

.cnt-here {
  width: 296px;
  height: 40px;
  flex-grow: 0;
  margin: 120px 18px;
  /* padding: 16px 80px 15px 79px; */
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
}

.qr-line{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 0.5em 0 !important;
  padding: 0;
}

.text2-sub3 {
  /* font-size: 20px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #27b49a;
  padding-top: 5%;
  padding-left: 5%;
}

.btn-1 {
  width: 220px;
  height: 44px;
  flex-grow: 0;
  margin: 0 0 16px;
  padding: 7px 16px;
  border-radius: 12px;
  background-color: #ffffff;
  border-color: #78aac3;
  color: #000000;
}

.btn-1:hover {
  width: 220px;
  height: 44px;
  flex-grow: 0;
  margin: 0 0 16px;
  padding: 7px 16px;
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
}

.e-ticket {
  padding: 10px;
  width: 100%;
  height: 340px;
  background-color: #78aac3;
  border-radius: 12px;
  margin-left: 15%;
}

.e-ticket-sub {
  /* padding: 5%; */
  width: 100%;
  height: 320px;
  background-color: #ffffff;
  border-radius: 12px;
  /* margin-left: 15%; */
}

.tkt-title {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #78aac3;
  margin-left: 5%;
  padding-top: 4%;
}

.tkt-table {
  font-size: 12px;
  margin-left: 5%;
}

.tkt-head {
  width: 50%;
  color: #666666;
}

.tkt-value {
  font-weight: 700;

}

.qr-code {
  text-align: center;
}
.end-text {
  font-size: 12px;
  font-weight: 700;
  margin-left: 5%;
  margin-top: -1%;
}

.nav-heading {
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #78aac3;
}

.main-clinic-bg {
  /* width: 1620px; */
  /* height: 80vh; */
  margin: 35px 50px 0 20px;
  padding: 31px 30px 29px;
  border-radius: 20px;
  background-color: #c4deee;
  margin: 0px;
  margin-top: 10px;
}

.sub-clinic-bg {
  /* height: 826px; */
  flex-grow: 0;
  margin-left: 5%;
  /* padding: 30px 30px 53px; */
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 10.5px;
}

.clinic-requests {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.add-recipient {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.text-input {
  /* border-color: #ffffff; */
  border-radius: 12px;
  /* border: solid 1px #9b9a9a !important; */
  background-color: #ffffff;
  height: 44px !important;

}
.text-input2 {
  /* border-color: #ffffff; */
  border-radius: 12px;
  border: solid 1px #9b9a9a !important;
  background-color: #ffffff;
  font-size:16px !important;
  height: 44px !important;
}

.image-input-clinic {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #666666;
  width: 114%;
  height: 220px;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px #9b9a9a !important;
}

.dialog {
  width: 450px;
  height: 650px;
  margin-top: -100%;
  z-index: 10;
  border-color: transparent;
  border-radius: 5px;
}

.logout-btn {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-area {
  /* position: absolute;
  left: 60%; */
  position: static;
  position: initial;
  text-align: end;
}

.vaccination-detail {
  background: #fff;
  border-radius: 20px;
  padding: 25px;
  position: relative;
  overflow: hidden;
  height: 160px
}
.user-name {
  font-weight: normal;
  margin-bottom: 10px;

  color: #f5a281;
}
.covide-infomation {
  font-weight: normal;
}
.background-image {
  position: absolute;
  height: 212px;
  right: -50px;
  top: 25px;
}

.image-upload-text3{
  /* margin-left: -3%; */
  margin-top: 1%;
}
.image-upload-text4{
  margin-left: -3%;
  margin-top: 1%;
}

/* li list-item {color: #78aac3} */

.list-item {
  height: 6px;
  color: #78aac3;
}

.action-btn {
  margin-top: -15px;
}

.action-btn:hover {
  margin-top: -15px;
}

.drp-btn {
  background-color: transparent;
  border-color: transparent !important;
}
.drp-btn:focus {
  outline: none;
  box-shadow: none;
}
.borderless td,
.borderless th {
  border: none;
}

.borderless td {
  font-size: 14px;
}
.borderless th {
  padding-top: 0px !important;
}

.user-proof {
  height: 193px;
  width: 193px;
  padding: 10px;

  border-radius: 8px;
  border: solid 1px #666666;
  background-color: #ffffff;
}

.filter-type {
  margin: 10px;
}
.reason-text {
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-top: 5%;
  padding-left: 1%;
}

.modal-input-text {
  margin-left: 1% !important;
}

.pending:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}

.pending:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #f5a281;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}
.approved:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #27b49a;
}

.approved:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #27b49a;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #27b49a;
}
.rejected:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}

.rejected:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ea4335;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #ea4335;
}
.view-all:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}

.view-all:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #3489ff;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #3489ff;
}

.recipient-dot {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.recipient-name {
  margin-left: 30%;
  margin-top: -15px;
  float: left;
}
.button-input {
  font-size: 14px;
}
.social-wallet-div {
  display: flex;
  flex-direction: column;
  margin-left: 9%;
}
.social-wallet-div .btn-1 {
  display: flex;
  font-size: 14px;
  text-align: center;
  line-height: 1.12;
  width: auto;
  margin: 6px 0px;
  padding: 10px;
}
.e-ticket {
  margin-left: -8%;
  margin: 0;
  width: 100%;
}

/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .main-clinic-bg {
    display: block;
    margin: 25px;
  }
  .sub-clinic-bg {
    max-width: 100%;
    padding: 13px;
    margin: 0px;
    margin-bottom: 3% !important;
    font-size: 15px;
  }
  .location-name {
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .details-section {
    display: flex;
    flex-direction: column;
  }
  .ds-section2 {
    max-width: 100%;
  }
  .view-more-div {
    padding: 10px;
    margin-left: -20px;
  }
  .upload-div {
    display: block;
  }
  .upload-div .upload-proof {
    max-width: 96%;
    margin-left: -18px;
  }
  .ds-section1 {
    display: none;
  }
  .e-ticket,
  .e-ticket-sub {
    height: auto;
  }
  .tkt-table {
    font-size: 8px;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.98px) {
  .main-clinic-bg {
    display: flex;
    flex-direction: column;
    /* margin: 3px; */
    margin: -5px;
    margin-top: 2%;
    font-size: 9px;
    padding: 10px;
  }
  .sub-clinic-bg {
    max-width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
  .form-group .form-control .button-input {
    font-size: 14px;
  }
  .add-recipient,
  .clinic-requests {
    font-size: 20px;
  }
  .nav-heading {
    line-height: 1.13;
    font-size: 23px;
  }
  .location-name {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .btn-area {
    /* left: 80%; */
    left: 65%;
  }
  .status-type {
    font-size: 12px;
  }
  .repeated-section {
    display: block;
    margin-top: 5% !important;
    padding: 6px;
  }
  .vaccination-detail {
    /* max-width: 80% !important; */
    max-width: 100% !important;
    padding: 16px;
    margin: 0px;
  }
  .user-name {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .welcome-text {
    font-size: 15px;
    margin-bottom: 2%;
  }
  .covide-infomation {
    font-size: 12px;
    line-height: 1.13;
    max-width: 100%;
  }
  .background-image {
    position: inherit;
    top: 0px;
    left: 27%;
    height: 55%;
  }
  .details-section {
    display: block;
    max-width: 100%;
    padding-right: 0px !important;
  }
  .ds-section1 {
    /* left: 25%;
    text-align: center; */
    display: none;
  }
  .ds-section2 {
    max-width: 100%;
  }
  .main-panel {
    max-width: 100%;
    display: flex;
    padding: 10px;
  }
  .main-panel-section1 {
    display: flex;
    flex-direction: column;
    padding: 1px 0px;
    margin: -3px;
  }
  .sction-part1 {
    max-width: 100%;
    padding: 10px;
  }
  .text1-main {
    font-size: 25px;
    text-align: center;
    padding: 10px;
  }
  .text1-sub {
    font-size: 16px;
    text-align: inherit;
    padding-left: 31px;
  }
  .text-button {
    display: flex;
  }
  .text-button .cnt-here {
    height: 35px !important;
  }
  .sction-part2 {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .sction-part2 .box-4 {
    max-width: 100%;
    margin-top: 5%;
  }
  .box-4 .text4-main {
    font-size: 25px;
  }
  .question-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .question-list ul {
    margin-bottom: 10%;
  }
  .question-list ul li {
    font-size: 13px;
    line-height: 1.11;
  }
  .box-3 {
    max-width: 100%;
    margin-top: 2%;
  }
  .status-div {
    display: flex;
  }
  .upload-div {
    display: flex;
    flex-direction: column;
  }
  .upload-div .upload-proof {
    padding: 0px;
    margin-left: -15px;
    max-width: 100%;
  }
  .table-list-data {
    display: block;
    font-size: 8px;
    overflow-x: scroll;
  }
  .recipient-name {
    margin-left: 48%;
  }
  .social-wallet-div .btn-1 {
    font-size: 10px;
    line-height: 1.12;
    text-align: center;
  }
  .e-ticket {
    margin: 0px;
    margin-top: 21%;
    margin-left: -16%;
  }
  .tkt-table {
    font-size: 8px;
  }
  .user-details-view {
    display: block;
    font-size: 11px;
    padding: 0px;
    margin: 0px;
  }
  .user-details-view table {
    /* display: flex;
    flex-direction: column; */
    font-size: 10px;
  }
  .user-proff-tr {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .nav-heading {
    font-size: 25px;
    margin-left: 10%;
  }
  .location-icon {
    margin-top: 5px !important;
  }

  .image-preview-modal {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .image-preview-modal .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border-radius: 100%;
    width: 30px;
  }

  .image-preview-modal .image {
    max-width: 100% !important;
  }
}

/*----------------Reset css----------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  text-decoration: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*----------------Utility classes-----------------*/
body {
  font-family: "Open Sans", sans-serif !important;
  color: #666666;
  background-color: #eff9ff !important; }

.container {
  margin: 0 auto;
  max-width: 1440px !important; }
  @media (max-width: 767px) {
    .container {
      padding: 0 15px; } }

.flex-middle-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.flex-between {
  display: flex;
  justify-content: space-between; }

.flex-center {
  display: flex;
  align-items: center; }

.flex-middle {
  display: flex;
  justify-content: center; }

.flex {
  display: flex; }

.flex-no-wrap {
  flex-wrap: nowrap !important; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.no-wrap {
  white-space: nowrap; }

.opnSans {
  font-family: "Open Sans", sans-serif !important; }

.Raleway {
  font-family: "Raleway", sans-serif !important; }

.w-100 {
  width: 100% !important; }

.w-90 {
  width: 90%; }

.w-80 {
  width: 80%; }

.w-70 {
  width: 70%; }

.w-60 {
  width: 60%; }

.w-50 {
  width: 50%; }

.w-40 {
  width: 40%; }

.w-30 {
  width: 30%; }

.w-20 {
  width: 20%; }

.w-10 {
  width: 10%; }

/*----------------Typography classes-----------------*/
h1,
.h1 {
  font-family: "Raleway", sans-serif !important;
  font-size: 48px;
  color: #78aac3 !important;
  font-weight: bold !important; }

h2,
.h2 {
  font-family: "Raleway", sans-serif !important;
  font-size: 42px;
  color: #78aac3 !important;
  font-weight: bold !important; }

h3,
.h3 {
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  color: #78aac3 !important;
  font-weight: bold !important; }

p {
  font-size: 20px;
  color: #666666;
  margin: 0;
  font-weight: 600; }

.font-36 {
  font-size: 36px; }

.font-32 {
  font-size: 32px; }

.font-18 {
  font-size: 18px; }

.font-14 {
  font-size: 14px; }

/*----------------forms classes-----------------*/
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"] {
  border-radius: 12px;
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  color: #666666;
  padding: 0 20px;
  height: 54px;
  width: 100%;
  margin-bottom: 25px;
  outline: none;
  border: 0; }

::-moz-placeholder {
  font-weight: normal;
  font-size: 1rem; }

:-ms-input-placeholder {
  font-weight: normal;
  font-size: 1rem; }

::placeholder {
  font-weight: normal;
  font-size: 1rem; }

.button {
  border-radius: 12px;
  font-size: 20px;
  padding: 15px 30px; }
  .button:disabled {
    opacity: 0.5; }

.btn-grey {
  background-color: #78aac3;
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold; }
  @media (max-width: 768px) {
    .btn-grey {
      padding: 10px 25px; } }
  .btn-grey.small {
    padding: 7px 30px;
    font-size: 18px; }
  .btn-grey:disabled {
    opacity: 0.5; }

.link {
  color: #f5a281;
  text-decoration: none;
  cursor: pointer;
  display: block; }
  .link:hover {
    color: #f5a281;
    text-decoration: underline; }

.blue-link {
  color: #3388ff;
  text-decoration: none;
  cursor: pointer; }
  .blue-link:hover {
    color: #3388ff;
    text-decoration: underline; }

/*----------------Comman classes-----------------*/
.bg-light-blue {
  background-color: #eff9ff; }

.main-wrapper {
  height: 100%;
  overflow-x: hidden;
  position: relative; }

.color-orange {
  color: #f5a281 !important; }

.color-blue {
  color: #78aac3 !important; }

.highlight {
  color: #f5a281 !important; }

/*------------- Spacings -----------*/
/*-----Margin---*/
.mb-0-5 {
  margin-bottom: 0.5em; }

.mb-1 {
  margin-bottom: 1em; }

.mb-2 {
  margin-bottom: 2em; }

.mb-3 {
  margin-bottom: 3em; }

.mb-4 {
  margin-bottom: 4em; }

.mb-5 {
  margin-bottom: 5em; }

.mt-0-5 {
  margin-top: 0.5em; }

.mt-1 {
  margin-top: 1em; }

.mt-2 {
  margin-top: 2em; }

.mt-3 {
  margin-top: 3em; }

.mt-4 {
  margin-top: 4em; }

.mt-5 {
  margin-top: 5em; }

/*left*/
.ml-0-5 {
  margin-left: 0.5em; }

.ml-1 {
  margin-left: 1em; }

.ml-2 {
  margin-left: 2em; }

.ml-3 {
  margin-left: 3em; }

.ml-4 {
  margin-left: 4em; }

.ml-5 {
  margin-left: 5em; }

/*left*/
.mr-0-5 {
  margin-right: 0.5em; }

.mr-1 {
  margin-right: 1em; }

.mr-2 {
  margin-right: 2em; }

.mr-3 {
  margin-right: 3em; }

.mr-4 {
  margin-right: 4em; }

.mr-5 {
  margin-right: 5em; }

/*-----padding---*/
.pb-0-5 {
  padding-bottom: 0.5em; }

.pb-1 {
  padding-bottom: 1em; }

.pb-2 {
  padding-bottom: 2em; }

.pb-3 {
  padding-bottom: 3em; }

.pb-4 {
  padding-bottom: 4em; }

.pb-5 {
  padding-bottom: 5em; }

.pt-0-5 {
  padding-top: 0.5em; }

.pt-1 {
  padding-top: 1em; }

.pt-2 {
  padding-top: 2em; }

.pt-3 {
  padding-top: 3em; }

.pt-4 {
  padding-top: 4em; }

.pt-5 {
  padding-top: 5em; }

/*---------spacing end--------*/
.button {
  min-height: 32px;
  border: none;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 10px 17px;
  white-space: nowrap;
  font-size: 1rem;
  font-family: "Raleway", sans-serif; }
  .button:focus, .button:active {
    outline: none;
    box-shadow: none; }

.button-primary {
  background-color: #78aac3;
  color: #fff; }
  .button-primary:hover {
    background-color: #78aac3; }
  .button-primary:active {
    background-color: #78aac3; }
  .button-primary:focus {
    background-color: #78aac3; }
  .button-primary:disabled {
    opacity: 0.5; }
  .button-primary .button-primary:focus,
  .button-primary .button-primary.focus {
    background-color: #78aac3; }

.button-primary:not(:disabled):not(.disabled):active {
  background-color: #78aac3; }

header {
  padding: 50px 0;
  align-items: center;
  display: flex; }
  header h2 {
    margin: 0; }
  header .location {
    margin-left: 25px;
    font-weight: 600;
    font-size: 16px;
    color: #666; }
  header .profile img {
    width: 35px;
    border-radius: 3px; }
  header .profile p {
    padding: 0 10px;
    font-size: 16px;
    margin: 0; }
  header .profile .options img {
    width: 20px; }

.form-wrapper {
  padding-top: 100px;
  display: flex;
  justify-content: space-between; }
  .form-wrapper .image-box {
    max-width: 50%;
    text-align: left; }
    .form-wrapper .image-box img {
      width: 100%; }
  .form-wrapper .form-box {
    max-width: 50%;
    width: 550px; }
    .form-wrapper .form-box p {
      margin: 0; }
    .form-wrapper .form-box .link {
      font-size: 18px;
      font-weight: 600; }
  .form-wrapper .bottom-sec {
    max-width: 550px;
    margin-left: auto; }

.survey-form .intro-sec {
  padding: 30px;
  border-radius: 20px;
  background-color: #ffffff; }
  .survey-form .intro-sec p {
    font-weight: 400;
    font-size: 16px; }

.survey-form .que-section {
  margin-top: 40px; }

.survey-form .ans-sec {
  display: flex;
  margin: 20px 0; }

.survey-form .custom_radio input[type="radio"] {
  display: none; }

.survey-form .custom_radio input[type="radio"] + label {
  position: relative;
  display: inline-block;
  margin-right: 2em;
  cursor: pointer;
  line-height: 1em;
  padding: 12px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #78aac3;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.survey-form .custom_radio input[type="radio"] + label:hover {
  transition: all 0.3s ease;
  box-shadow: 0 0 0px 2px #e0e0e0; }

.survey-form .custom_radio input[type="radio"]:checked + label {
  transition: all 0.3s ease;
  background-color: #78aac3;
  color: #fff; }

.form-steps {
  width: 150px;
  display: flex;
  margin-left: auto;
  flex-direction: column;
  text-align: center;
  margin-top: 60px;
  max-height: 500px; }
  .form-steps span.number {
    background: #fff;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 2.2;
    margin: 0 auto;
    cursor: pointer; }
  .form-steps .step {
    height: 33%;
    margin-top: 10px; }
    .form-steps .step p {
      font-size: 14px;
      font-weight: 600;
      padding: 5px 0 10px 0;
      margin: 0; }
    .form-steps .step .step-border {
      height: 55%;
      width: 3px;
      background-color: #fff;
      margin: 0 auto;
      border-radius: 5px;
      position: relative;
      overflow: hidden; }
    .form-steps .step.active p {
      color: #f5a281; }
    .form-steps .step.active .active-border {
      background-color: #78aac3;
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: 3px; }
  .form-steps.step1 .active .active-border {
    height: 50%; }
  .form-steps.step2 .active:first-child .active-border {
    height: 100%; }
  .form-steps.step2 .active .active-border {
    height: 50%; }
  .form-steps.step3 .active .active-border {
    height: 100%; }

.peronsl-detail-header .covid-passport {
  color: #78aac3;
  font-size: 42px;
  padding-right: 25px; }

.peronsl-detail-header .your-location {
  font-weight: 500;
  display: flex;
  align-items: center; }
  .peronsl-detail-header .your-location .location-icon {
    height: 26px;
    margin-right: 7px; }

.peronsl-detail-header .user-profile {
  cursor: pointer; }
  .peronsl-detail-header .user-profile .user-photo {
    height: 30px;
    width: 30px;
    border-radius: 4px; }
  .peronsl-detail-header .user-profile span {
    padding: 0 12px; }
  .peronsl-detail-header .user-profile .down-arrow {
    width: 12px; }

.survey-form .repeated-section {
  overflow: hidden; }
  .survey-form .repeated-section .vaccination-detail {
    background: #fff;
    border-radius: 20px;
    padding: 25px;
    position: relative; }
    .survey-form .repeated-section .vaccination-detail .user-name {
      font-weight: normal;
      margin-bottom: 10px; }
      .survey-form .repeated-section .vaccination-detail .user-name span {
        color: #f5a281; }
    .survey-form .repeated-section .vaccination-detail .covide-infomation {
      font-weight: normal; }
    .survey-form .repeated-section .vaccination-detail .background-image {
      position: absolute;
      height: 212px;
      right: -50px;
      top: 10px; }

.survey-form .give-detail-steps {
  min-width: 50%; }
  .survey-form .give-detail-steps .questions {
    padding: 15px; }
    .survey-form .give-detail-steps .questions p {
      margin-bottom: 20px; }
      .survey-form .give-detail-steps .questions p span {
        padding-right: 10px; }
    .survey-form .give-detail-steps .questions .answer-wrapper {
      display: inline-flex;
      height: 50px;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 10px;
      margin-bottom: 15px;
      width: 10%; }
      .survey-form .give-detail-steps .questions .answer-wrapper .option {
        background: #fff;
        height: 100%;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 10px;
        border-radius: 12px;
        cursor: pointer;
        padding: 0 10px;
        transition: all 0.3s ease;
        white-space: nowrap;
        color: #78aac3;
        font-weight: bold;
        font-size: 1rem; }
      .survey-form .give-detail-steps .questions .answer-wrapper input[type="radio"] {
        display: none; }
      .survey-form .give-detail-steps .questions .answer-wrapper input[type="radio"]:checked + label {
        border-color: #78aac3;
        background: #78aac3;
        color: #fff; }
    .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox {
      position: absolute;
      opacity: 0; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 40px; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 8px;
        position: absolute;
        left: 0; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox:hover + label:before {
        background: #78aac3; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox:checked + label:before {
        background: #78aac3; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox:disabled + label {
        color: #b8b8b8;
        cursor: auto; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox:disabled + label:before {
        box-shadow: none;
        background: #ddd; }
      .survey-form .give-detail-steps .questions .custom-checkbox .styled-checkbox:checked + label:after {
        content: "";
        position: absolute;
        left: 9px;
        top: 14px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg); }
    .survey-form .give-detail-steps .questions .custom-checkbox .unstyled {
      margin: 0;
      padding: 0;
      list-style-type: none;
      width: 33%; }
    .survey-form .give-detail-steps .questions .custom-checkbox li {
      margin: 20px 0; }
    .survey-form .give-detail-steps .questions .vaccine-detail .custom-select {
      border-radius: 12px;
      background-color: #ffffff;
      font-size: 1rem;
      font-weight: normal;
      color: #666666;
      padding: 0 20px;
      height: 54px;
      width: 100%;
      margin-bottom: 25px;
      outline: none;
      border: 0; }
    .survey-form .give-detail-steps .questions .vaccine-detail .upload-proof {
      background-color: #fff;
      padding: 20px;
      border-radius: 12px; }
      .survey-form .give-detail-steps .questions .vaccine-detail .upload-proof span {
        font-size: 1rem; }
      .survey-form .give-detail-steps .questions .vaccine-detail .upload-proof .fileUpload {
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        font-size: 1em;
        font-weight: bold;
        margin: 1.25em auto;
        /*20px/16px 0*/
        overflow: hidden;
        padding: 0.875em;
        /*14px/16px*/
        position: relative;
        text-align: center; }
      .survey-form .give-detail-steps .questions .vaccine-detail .upload-proof .fileUpload input.upload {
        position: absolute;
        top: 10px;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        height: 46px;
        width: 50%; }
      .survey-form .give-detail-steps .questions .vaccine-detail .upload-proof .upload-icon {
        height: 25px;
        margin-right: 10px; }

.open-extra-input {
  margin-top: -46px;
  position: relative;
  max-width: 310px;
  margin-left: 37%;
  height: 77px; }
  .open-extra-input button {
    position: absolute;
    right: 8px;
    top: 8px; }
  .open-extra-input input {
    margin-bottom: 0; }

.open-extra-input2 {
  margin-top: -79px;
  position: relative;
  max-width: 310px;
  margin-left: 66%;
  height: 77px; }
  .open-extra-input2 button {
    position: absolute;
    right: 8px;
    top: 8px; }
  .open-extra-input2 input {
    margin-bottom: 0; }

.save-continue {
  display: flex;
  justify-content: center; }
  .save-continue button {
    width: 50%; }

@media screen and (max-width: 767px) {
  .survey-form .repeated-section {
    overflow: hidden; }
    .survey-form .repeated-section .vaccination-detail h3 {
      font-size: 20px; }
    .survey-form .repeated-section .vaccination-detail .covide-infomation {
      font-size: 18px; }
    .survey-form .repeated-section .vaccination-detail .background-image {
      position: absolute;
      height: 100px;
      right: 0px;
      top: 10px;
      left: unset; }
  .survey-form .give-detail-steps .questions {
    padding: 15px; }
    .survey-form .give-detail-steps .questions .custom-checkbox {
      display: block !important; }
      .survey-form .give-detail-steps .questions .custom-checkbox .unstyled {
        width: 100%; } }

@media screen and (max-width: 600px) {
  .survey-form .repeated-section {
    overflow: hidden; }
    .survey-form .repeated-section .vaccination-detail h3 {
      font-size: 18px; }
    .survey-form .repeated-section .vaccination-detail .covide-infomation {
      font-size: 14px; }
    .survey-form .repeated-section .vaccination-detail .background-image {
      position: absolute;
      height: 90px;
      right: 0px;
      top: 10px; } }

@media screen and (max-width: 550px) {
  .survey-form .repeated-section {
    overflow: hidden; }
    .survey-form .repeated-section .vaccination-detail h3 {
      font-size: 12px; }
    .survey-form .repeated-section .vaccination-detail .covide-infomation {
      font-size: 12px; }
    .survey-form .repeated-section .vaccination-detail .background-image {
      position: absolute;
      height: 70px;
      right: 0px;
      top: 10px; } }

@media screen and (max-width: 481px) {
  .survey-form .give-detail-steps .questions {
    padding: 15px; }
    .survey-form .give-detail-steps .questions .answer-wrapper {
      display: block !important; }
      .survey-form .give-detail-steps .questions .answer-wrapper .option {
        width: 100% !important; } }

@media screen and (min-width: 768px) and (max-width: 810px) {
  .survey-form .repeated-section {
    overflow: hidden; }
    .survey-form .repeated-section .vaccination-detail h3 {
      font-size: 18px; }
    .survey-form .repeated-section .vaccination-detail .covide-infomation {
      font-size: 14px; }
    .survey-form .repeated-section .vaccination-detail .background-image {
      position: absolute;
      height: 150px;
      right: 0px;
      top: 10px; } }

@media screen and (min-width: 811px) and (max-width: 998px) {
  .survey-form .repeated-section {
    overflow: hidden; }
    .survey-form .repeated-section .vaccination-detail h3 {
      font-size: 25px; }
    .survey-form .repeated-section .vaccination-detail .covide-infomation {
      font-size: 14px; }
    .survey-form .repeated-section .vaccination-detail .background-image {
      position: absolute;
      height: 170px;
      right: 0px;
      top: 10px; } }

.main-bg {
  background-color: #eff9ff;
  width: 100%;
  height: 100vh;
}
.btn-gry {
  background-color: gray;
}

.hero-title {
  /* margin-top: 6%;
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3; */
}

.hero-image {
  width: 70%;
  margin: 11% 10%;
  -o-object-fit: contain;
     object-fit: contain;
}

/* .sign-up {
  margin-top: 12%;
} */

.stay-safe {
  margin-left: 6%;
}

.sign-up-title {
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3;
}

.link-button {
  color: #f5a281;
}

.input-fields {
  margin-left: 1%;
  margin-top: 3%;
}

.text-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #666666;
}

.error {
  color: red;
}

.form-group .error {
  position: absolute;
  top: 16px;
  right: 20px;
}

.ok-box{
  width: 66px;
  height: 35px;
  flex-grow: 0;
  padding: 8px 22px 7px;
  border-radius: 12px;
  background-color: #78aac3;
  color: white;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.ok-view{
  position: sticky;
  margin-left: -10px;
  margin-top: -12.5%;
  /* cursor: pointer; */
}



.form-group {
  position: relative;
}

.form-inline .form-control {
  display: inline-block;
  vertical-align: center;
  width: auto;
}

.button-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #ffffff;
  background-color: #78aac3;
}

.recipient-tab1 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}
.recipient-tab2 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}

.selection {
  margin-left: 4%;
}

.small-avatar {
  right: calc(100% - 100%);
}

.selection-text {
  color: #666666;
  margin-left: 7%;
}

.radio {
  /* width: 20px; */
  height: 20px;
  flex-grow: 0;
  margin: 8px 12px 8px 0;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #f5a281;
}

.captcha {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
}

.link-text {
  color: #3388ff;
  cursor: pointer;
}

.checkbox {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
  color: #666666;
}

.pg-bar2 .progress-bar {
  background-color: #78aac3;
  color: white;
  height: 7px;
}

.pg-bar2 {
  height: 7px;
}

.pg-bar {
  margin-left: 4%;
}

.pg-title {
  padding-left: 0px;
}

.submission-image {
  width: 65%;
  margin-top: 20%;
  margin-left: 15%;
}

.image1 {
  margin-left: 30%;
  margin-top: 15%;
  width: 8%;
}

.image-input{
  margin-left: 0% !important;
}

.submission-text-group {
  margin-right: 27%;
  font-size: 45px;
  font-weight: 500;
}

.submission-text1 {
  margin-top: 1%;
  color: #f5a281;
  /* margin-left: 5%; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.submission-text2 {
  /* margin-top: 1%; */
  color: #f5a281;
  /* margin-left: -8%; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.submission-text3 {
  /* margin-top: 1%; */
  color: #f5a281;
  /* margin-left: -10%; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.dashboard-btn {
  margin-left: 19%;
  margin-top: 2%;
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
  text-align: center;
  height: auto;
}
.dashboard-btn:hover {
  color: #ffffff;
}

.navigation{
  margin-top: 2%;
  margin-bottom: 2%;
}
.survey-main{
  margin-bottom: 2%;
}

.fileUpload{
  margin : 0px auto !important;
}
.btn-wrapper {
  margin-top: 80px;
}
.btn-wrapper a {
  width: 100%;
  padding: 16px 63px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media screen and (max-width: 768px) {
  .submission-text-group {
    margin-right: 0px;
    font-size: 25px;
    font-weight: 500;
    padding-left: 70px;
  }

  .image1 {
    margin-left: 50%;
    margin-top: 5%;
    width: 8%;
  }

  .dashboard-btn {
    margin-left: 39%;
    margin-top: -9%;
    height: auto;
  }
  .image-preview-modal {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image-preview-modal button {
    position: absolute;
    top: 0;
    right: 20px;
    background: white;
    border-radius: 100%;
    width: 30px;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1460px) {
  .dashboard-btn {
    margin-left: 15%;
  }
  .submission-text-group {
    font-size: 35px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1149px) {
  .dashboard-btn {
    margin-left: 10%;
  }
  .submission-text-group {
    font-size: 30px;
  }
}

@media screen and (min-width: 838px) and (max-width: 991px) {
  .dashboard-btn {
    margin-left: 7%;
  }

  .submission-text-group {
    font-size: 25px;
  }
}

@media screen and (min-width: 769px) and (max-width: 837px) {
  .dashboard-btn {
    margin-left: 4%;
  }
  .submission-text-group {
    font-size: 22px;
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .dashboard-btn {
    margin-left: 22%;
  }
}
@media screen and (min-width: 700px) and (max-width: 767px) {
  .dashboard-btn {
    margin-left: 40%;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-btn {
    margin-left: 30%;
  }
}

@media screen and (min-width: 501px) and (max-width: 599px) {
  .dashboard-btn {
    margin-left: 34%;
  }
}

@media screen and (max-width: 499px) {
  .dashboard-btn {
    margin-left: 22%;
  }

  .submission-text-group {
    font-size: 20px;
  } 
}

@media screen and (max-width: 350px) {
  .dashboard-btn {
    margin-left: 10%;
  }

  .submission-text-group {
    font-size: 17px;
  } 
}

@media screen and (max-width: 300px) {
  .dashboard-btn {
    margin: 0;
  }

  .submission-text-group {
    font-size: 20px;
    padding: 0;
  } 
}
.otp-input {
  display: flex; }

.otp-input input {
  margin: 5px;
  width: 15%; }

.my-otp input {
  padding: 0 !important;
  width: 100% !important;
  margin: 5px; }

.form-section {
  padding: 0 10% !important; }

.otp-sent-email {
  word-break: break-all; }

@media screen and (max-width: 1045px) {
  .sign-up-title {
    font-size: 40px; }
  .flex-center p {
    font-size: 15px; } }

@media screen and (min-width: 768px) {
  .otp-sent-email {
    margin: 6% !important; } }

@media screen and (max-width: 767px) {
  .otp-form {
    display: inline-flex; }
  .form-section {
    padding: 0 20% !important; }
  .sign-up-title {
    font-size: 25px; }
  .otp-sent-email {
    margin: 0 !important; } }

@media screen and (min-width: 850px) and (max-width: 1045px) {
  .sign-up-title {
    font-size: 30px; }
  .flex-center p {
    font-size: 12px; }
  .page-image .hero-image {
    width: 70%; }
  .captcha {
    font-size: 13px !important; } }

@media screen and (min-width: 800px) and (max-width: 850px) {
  .sign-up-title {
    font-size: 27px; } }

@media screen and (min-width: 768px) and (max-width: 799px) {
  .sign-up-title {
    font-size: 27px; }
  .flex-center p {
    font-size: 10px; }
  .submit-btn {
    font-size: 16px; }
  .captcha {
    font-size: 12px !important; } }

@media screen and (min-width: 601px) and (max-width: 700px) {
  .page-image .hero-image {
    width: 85%; } }

@media screen and (min-width: 419px) and (max-width: 600px) {
  .page-image .hero-image {
    width: 80%; } }

@media screen and (min-width: 1050px) and (max-width: 1150px) {
  .sign-up-title {
    font-size: 38px; } }

@media screen and (min-width: 361px) and (max-width: 418px) {
  .flex-center p {
    font-size: 12px; }
  .page-image .hero-image {
    width: 75%; } }

@media screen and (min-width: 310px) and (max-width: 360px) {
  .sign-up-title {
    font-size: 22px; }
  .flex-center p {
    font-size: 10px; }
  .flex-center a {
    font-size: 12px; }
  .captcha {
    font-size: 10px !important; }
  .page-image .hero-image {
    width: 70%; } }

@media screen and (min-width: 300px) and (max-width: 500px) {
  .submit-btn {
    font-size: 13px;
    padding: 10px; } }

