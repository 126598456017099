/*------------- Spacings -----------*/
/*-----Margin---*/
.mb-0-5 {
  margin-bottom: 0.5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 4em;
}
.mb-5 {
  margin-bottom: 5em;
}
.mt-0-5 {
  margin-top: 0.5em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.mt-4 {
  margin-top: 4em;
}
.mt-5 {
  margin-top: 5em;
}

/*left*/
.ml-0-5 {
  margin-left: 0.5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.ml-3 {
  margin-left: 3em;
}
.ml-4 {
  margin-left: 4em;
}
.ml-5 {
  margin-left: 5em;
}
/*left*/
.mr-0-5 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.mr-3 {
  margin-right: 3em;
}
.mr-4 {
  margin-right: 4em;
}
.mr-5 {
  margin-right: 5em;
}

/*-----padding---*/
.pb-0-5 {
  padding-bottom: 0.5em;
}
.pb-1 {
  padding-bottom: 1em;
}
.pb-2 {
  padding-bottom: 2em;
}
.pb-3 {
  padding-bottom: 3em;
}
.pb-4 {
  padding-bottom: 4em;
}
.pb-5 {
  padding-bottom: 5em;
}
.pt-0-5 {
  padding-top: 0.5em;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pt-3 {
  padding-top: 3em;
}
.pt-4 {
  padding-top: 4em;
}
.pt-5 {
  padding-top: 5em;
}
/*---------spacing end--------*/
