/* .main-bg {
  background-color: #eff9ff;
  width: 100%;
  height: 100vh;
} */

/* .hero-image {
  width: 80%;
  margin: 11% 10%;
  object-fit: contain;
} */

.user-icon{
  width: 25px;
  height: 25px;
  margin: 0px 1px -7px 0px;
  object-fit: contain;
  border-radius: 3px;
}

.main-panel {
  /* height: 70%; */
  /* margin: 303.3px 87px 0 719px; */
  margin-top: 8%;
  padding: 30px;
  border-radius: 20px;
  background-color: #c4deee;
}

.box-1 {
  height: 180px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
}
.box-2 {
  margin-top: 5%;
  height: 430px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px;
}

.box-3 {
  width: 120%;
  height: 220px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
}
.box-4 {
  width: 120%;
  margin-top: 17%;
  height: 316px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #ffffff;
}

.text1-main {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 5%;
  padding-left: 5%;
}
.text1-sub {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 1%;
  padding-bottom: 2%;
}
.text1-sub1 {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 6%;
  padding-bottom: 2%;
}
.text2-main {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 5%;
  padding-left: 2%;
  padding-bottom: 1%;
}
.text2-sub1 {
  /* font-size: 18px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-top: 5%;
  padding-left: 2%;
}

.text2-sub2 {
  /* font-size: 20px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f5a281;
  padding-top: 5%;
  padding-left: 5%;
}

.text4-main {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 5%;
  padding-left: 5%;
}

.text4-sub {
  width: 80%;
  height: 50px;
  flex-grow: 0;
  margin: 16px 0px 30px 34px;
  /* font-size: 18px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.view-more {
  width: 154px;
  height: 43px;
  flex-grow: 0;
  margin-left: 21%;
  padding: 11px 32px;
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
}

.cnt-here {
  width: 296px;
  height: 40px;
  flex-grow: 0;
  margin: 120px 18px;
  /* padding: 16px 80px 15px 79px; */
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
}

.qr-line{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 0.5em 0 !important;
  padding: 0;
}

.text2-sub3 {
  /* font-size: 20px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #27b49a;
  padding-top: 5%;
  padding-left: 5%;
}

.btn-1 {
  width: 220px;
  height: 44px;
  flex-grow: 0;
  margin: 0 0 16px;
  padding: 7px 16px;
  border-radius: 12px;
  background-color: #ffffff;
  border-color: #78aac3;
  color: #000000;
}

.btn-1:hover {
  width: 220px;
  height: 44px;
  flex-grow: 0;
  margin: 0 0 16px;
  padding: 7px 16px;
  border-radius: 12px;
  background-color: #78aac3;
  color: #ffffff;
}

.e-ticket {
  padding: 10px;
  width: 100%;
  height: 340px;
  background-color: #78aac3;
  border-radius: 12px;
  margin-left: 15%;
}

.e-ticket-sub {
  /* padding: 5%; */
  width: 100%;
  height: 320px;
  background-color: #ffffff;
  border-radius: 12px;
  /* margin-left: 15%; */
}

.tkt-title {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #78aac3;
  margin-left: 5%;
  padding-top: 4%;
}

.tkt-table {
  font-size: 12px;
  margin-left: 5%;
}

.tkt-head {
  width: 50%;
  color: #666666;
}

.tkt-value {
  font-weight: 700;

}

.qr-code {
  text-align: center;
}
.end-text {
  font-size: 12px;
  font-weight: 700;
  margin-left: 5%;
  margin-top: -1%;
}

.nav-heading {
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #78aac3;
}

.main-clinic-bg {
  /* width: 1620px; */
  /* height: 80vh; */
  margin: 35px 50px 0 20px;
  padding: 31px 30px 29px;
  border-radius: 20px;
  background-color: #c4deee;
  margin: 0px;
  margin-top: 10px;
}

.sub-clinic-bg {
  /* height: 826px; */
  flex-grow: 0;
  margin-left: 5%;
  /* padding: 30px 30px 53px; */
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 10.5px;
}

.clinic-requests {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.add-recipient {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.text-input {
  /* border-color: #ffffff; */
  border-radius: 12px;
  /* border: solid 1px #9b9a9a !important; */
  background-color: #ffffff;
  height: 44px !important;

}
.text-input2 {
  /* border-color: #ffffff; */
  border-radius: 12px;
  border: solid 1px #9b9a9a !important;
  background-color: #ffffff;
  font-size:16px !important;
  height: 44px !important;
}

.image-input-clinic {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #666666;
  width: 114%;
  height: 220px;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px #9b9a9a !important;
}

.dialog {
  width: 450px;
  height: 650px;
  margin-top: -100%;
  z-index: 10;
  border-color: transparent;
  border-radius: 5px;
}

.logout-btn {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-area {
  /* position: absolute;
  left: 60%; */
  position: initial;
  text-align: end;
}

.vaccination-detail {
  background: #fff;
  border-radius: 20px;
  padding: 25px;
  position: relative;
  overflow: hidden;
  height: 160px
}
.user-name {
  font-weight: normal;
  margin-bottom: 10px;

  color: #f5a281;
}
.covide-infomation {
  font-weight: normal;
}
.background-image {
  position: absolute;
  height: 212px;
  right: -50px;
  top: 25px;
}

.image-upload-text3{
  /* margin-left: -3%; */
  margin-top: 1%;
}
.image-upload-text4{
  margin-left: -3%;
  margin-top: 1%;
}

/* li list-item {color: #78aac3} */

.list-item {
  height: 6px;
  color: #78aac3;
}

.action-btn {
  margin-top: -15px;
}

.action-btn:hover {
  margin-top: -15px;
}

.drp-btn {
  background-color: transparent;
  border-color: transparent !important;
}
.drp-btn:focus {
  outline: none;
  box-shadow: none;
}
.borderless td,
.borderless th {
  border: none;
}

.borderless td {
  font-size: 14px;
}
.borderless th {
  padding-top: 0px !important;
}

.user-proof {
  height: 193px;
  width: 193px;
  padding: 10px;

  border-radius: 8px;
  border: solid 1px #666666;
  background-color: #ffffff;
}

.filter-type {
  margin: 10px;
}
.reason-text {
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-top: 5%;
  padding-left: 1%;
}

.modal-input-text {
  margin-left: 1% !important;
}

.pending:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}

.pending:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #f5a281;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}
.approved:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #27b49a;
}

.approved:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #27b49a;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #27b49a;
}
.rejected:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}

.rejected:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ea4335;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #ea4335;
}
.view-all:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #f5a281;
}

.view-all:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #3489ff;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #3489ff;
}

.recipient-dot {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.recipient-name {
  margin-left: 30%;
  margin-top: -15px;
  float: left;
}
.button-input {
  font-size: 14px;
}
.social-wallet-div {
  display: flex;
  flex-direction: column;
  margin-left: 9%;
}
.social-wallet-div .btn-1 {
  display: flex;
  font-size: 14px;
  text-align: center;
  line-height: 1.12;
  width: auto;
  margin: 6px 0px;
  padding: 10px;
}
.e-ticket {
  margin-left: -8%;
  margin: 0;
  width: 100%;
}

/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .main-clinic-bg {
    display: block;
    margin: 25px;
  }
  .sub-clinic-bg {
    max-width: 100%;
    padding: 13px;
    margin: 0px;
    margin-bottom: 3% !important;
    font-size: 15px;
  }
  .location-name {
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .details-section {
    display: flex;
    flex-direction: column;
  }
  .ds-section2 {
    max-width: 100%;
  }
  .view-more-div {
    padding: 10px;
    margin-left: -20px;
  }
  .upload-div {
    display: block;
  }
  .upload-div .upload-proof {
    max-width: 96%;
    margin-left: -18px;
  }
  .ds-section1 {
    display: none;
  }
  .e-ticket,
  .e-ticket-sub {
    height: auto;
  }
  .tkt-table {
    font-size: 8px;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.98px) {
  .main-clinic-bg {
    display: flex;
    flex-direction: column;
    /* margin: 3px; */
    margin: -5px;
    margin-top: 2%;
    font-size: 9px;
    padding: 10px;
  }
  .sub-clinic-bg {
    max-width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
  .form-group .form-control .button-input {
    font-size: 14px;
  }
  .add-recipient,
  .clinic-requests {
    font-size: 20px;
  }
  .nav-heading {
    line-height: 1.13;
    font-size: 23px;
  }
  .location-name {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .btn-area {
    /* left: 80%; */
    left: 65%;
  }
  .status-type {
    font-size: 12px;
  }
  .repeated-section {
    display: block;
    margin-top: 5% !important;
    padding: 6px;
  }
  .vaccination-detail {
    /* max-width: 80% !important; */
    max-width: 100% !important;
    padding: 16px;
    margin: 0px;
  }
  .user-name {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .welcome-text {
    font-size: 15px;
    margin-bottom: 2%;
  }
  .covide-infomation {
    font-size: 12px;
    line-height: 1.13;
    max-width: 100%;
  }
  .background-image {
    position: inherit;
    top: 0px;
    left: 27%;
    height: 55%;
  }
  .details-section {
    display: block;
    max-width: 100%;
    padding-right: 0px !important;
  }
  .ds-section1 {
    /* left: 25%;
    text-align: center; */
    display: none;
  }
  .ds-section2 {
    max-width: 100%;
  }
  .main-panel {
    max-width: 100%;
    display: flex;
    padding: 10px;
  }
  .main-panel-section1 {
    display: flex;
    flex-direction: column;
    padding: 1px 0px;
    margin: -3px;
  }
  .sction-part1 {
    max-width: 100%;
    padding: 10px;
  }
  .text1-main {
    font-size: 25px;
    text-align: center;
    padding: 10px;
  }
  .text1-sub {
    font-size: 16px;
    text-align: inherit;
    padding-left: 31px;
  }
  .text-button {
    display: flex;
  }
  .text-button .cnt-here {
    height: 35px !important;
  }
  .sction-part2 {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .sction-part2 .box-4 {
    max-width: 100%;
    margin-top: 5%;
  }
  .box-4 .text4-main {
    font-size: 25px;
  }
  .question-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .question-list ul {
    margin-bottom: 10%;
  }
  .question-list ul li {
    font-size: 13px;
    line-height: 1.11;
  }
  .box-3 {
    max-width: 100%;
    margin-top: 2%;
  }
  .status-div {
    display: flex;
  }
  .upload-div {
    display: flex;
    flex-direction: column;
  }
  .upload-div .upload-proof {
    padding: 0px;
    margin-left: -15px;
    max-width: 100%;
  }
  .table-list-data {
    display: block;
    font-size: 8px;
    overflow-x: scroll;
  }
  .recipient-name {
    margin-left: 48%;
  }
  .social-wallet-div .btn-1 {
    font-size: 10px;
    line-height: 1.12;
    text-align: center;
  }
  .e-ticket {
    margin: 0px;
    margin-top: 21%;
    margin-left: -16%;
  }
  .tkt-table {
    font-size: 8px;
  }
  .user-details-view {
    display: block;
    font-size: 11px;
    padding: 0px;
    margin: 0px;
  }
  .user-details-view table {
    /* display: flex;
    flex-direction: column; */
    font-size: 10px;
  }
  .user-proff-tr {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .nav-heading {
    font-size: 25px;
    margin-left: 10%;
  }
  .location-icon {
    margin-top: 5px !important;
  }

  .image-preview-modal {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .image-preview-modal .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border-radius: 100%;
    width: 30px;
  }

  .image-preview-modal .image {
    max-width: 100% !important;
  }
}
