.peronsl-detail-header {
  .covid-passport {
    color: #78aac3;
    font-size: 42px;
    padding-right: 25px;
  }
  .your-location {
    font-weight: 500;
    display: flex;
    align-items: center;
    .location-icon {
      height: 26px;
      margin-right: 7px;
    }
  }
  .user-profile {
    cursor: pointer;
    .user-photo {
      height: 30px;
      width: 30px;
      border-radius: 4px;
    }
    span {
      padding: 0 12px;
    }
    .down-arrow {
      width: 12px;
    }
  }
}

.survey-form {
  .repeated-section {
    overflow: hidden;
    .vaccination-detail {
      background: #fff;
      border-radius: 20px;
      padding: 25px;
      position: relative;
      .user-name {
        font-weight: normal;
        margin-bottom: 10px;
        span {
          color: #f5a281;
        }
      }
      .covide-infomation {
        font-weight: normal;
      }
      .background-image {
        position: absolute;
        height: 212px;
        right: -50px;
        top: 10px;
      }
    }
  }
  .give-detail-steps {
    min-width: 50%;
    .questions {
      padding: 15px;
      p {
        margin-bottom: 20px;
        span {
          padding-right: 10px;
        }
      }
      .answer-wrapper {
        display: inline-flex;
        height: 50px;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 10px;
        margin-bottom: 15px;
        width: 10%;
        .option {
          background: #fff;
          height: 100%;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: 0 10px;
          border-radius: 12px;
          cursor: pointer;
          padding: 0 10px;
          transition: all 0.3s ease;
          white-space: nowrap;
          color: #78aac3;
          font-weight: bold;
          font-size: 1rem;
        }
        input[type="radio"] {
          display: none;
        }
        input[type="radio"]:checked + label {
          border-color: #78aac3;
          background: #78aac3;
          color: #fff;
        }
      }
      .custom-checkbox {
        .styled-checkbox {
          position: absolute; // take it out of document flow
          opacity: 0; // hide it

          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            padding-left: 40px;
          }

          // Box.
          & + label:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 30px;
            height: 30px;
            background: white;
            border-radius: 8px;
            position: absolute;
            left: 0;
          }

          // Box hover
          &:hover + label:before {
            background: #78aac3;
          }

          // Box focus
          &:focus + label:before {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
          }

          // Box checked
          &:checked + label:before {
            background: #78aac3;
          }

          // Disabled state label.
          &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
          }

          // Disabled box.
          &:disabled + label:before {
            box-shadow: none;
            background: #ddd;
          }

          // Checkmark. Could be replaced with an image
          &:checked + label:after {
            content: "";
            position: absolute;
            left: 9px;
            top: 14px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
              4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
            transform: rotate(45deg);
          }
        }

        .unstyled {
          margin: 0;
          padding: 0;
          list-style-type: none;
          width: 33%;
        }

        li {
          margin: 20px 0;
        }
      }
      .vaccine-detail {
        .custom-select {
          border-radius: 12px;
          background-color: #ffffff;
          font-size: 1rem;
          font-weight: normal;
          color: #666666;
          padding: 0 20px;
          height: 54px;
          width: 100%;
          margin-bottom: 25px;
          outline: none;
          border: 0;
        }
        .upload-proof {
          background-color: #fff;
          padding: 20px;
          border-radius: 12px;
          span {
            font-size: 1rem;
          }
          .fileUpload {
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            font-size: 1em;
            font-weight: bold;
            margin: 1.25em auto; /*20px/16px 0*/
            overflow: hidden;
            padding: 0.875em; /*14px/16px*/
            position: relative;
            text-align: center;
          }
          .fileUpload input.upload {
            position: absolute;
            top: 10px;
            margin: 0;
            padding: 0;
            font-size: 20px;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
            height: 46px;
            width: 50%;
          }
          .upload-icon {
            height: 25px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.open-extra-input {
  margin-top: -46px;
  position: relative;
  max-width: 310px;
  margin-left: 37%;
  height: 77px;
  button {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  input {
    margin-bottom: 0;
  }
}

.open-extra-input2 {
  margin-top: -79px;
    position: relative;
    max-width: 310px;
    margin-left: 66%;
    height: 77px;
  button {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  input {
    margin-bottom: 0;
  }
}



.save-continue {
  display: flex;
  justify-content: center;
  button {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .survey-form {
    .repeated-section {
      overflow: hidden;
      .vaccination-detail {
        h3 {
          font-size: 20px;
        }
        .covide-infomation {
          font-size: 18px;
        }
        .background-image {
          position: absolute;
          height: 100px;
          right: 0px;
          top: 10px;
          left: unset;
        }
      }
    }
    .give-detail-steps {
      .questions {
        padding: 15px;
        .custom-checkbox {
          display: block !important;
          .unstyled {
            width: 100%;
          }          
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .survey-form {
    .repeated-section {
      overflow: hidden;
      .vaccination-detail {
        h3 {
          font-size:18px;
        }
        .covide-infomation {
          font-size: 14px;
        }
        .background-image {
          position: absolute;
          height: 90px;
          right: 0px;
          top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .survey-form {
    .repeated-section {
      overflow: hidden;
      .vaccination-detail {
        h3 {
          font-size:12px;
        }
        .covide-infomation {
          font-size: 12px;
        }
        .background-image {
          position: absolute;
          height: 70px;
          right: 0px;
          top: 10px;
        }
      }
    }
  }
}


@media screen and (max-width: 481px) {
  .survey-form {    
    .give-detail-steps {
      .questions {
        padding: 15px;
        .answer-wrapper {
          display: block !important;
          .option {
            width: 100% !important; 
          }
        }  
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width: 810px) {
  .survey-form {
    .repeated-section {
      overflow: hidden;
      .vaccination-detail {
        h3 {
          font-size:18px;
        }
        .covide-infomation {
          font-size: 14px;
        }
        .background-image {
          position: absolute;
          height: 150px;
          right: 0px;
          top: 10px;
        }
      }
    }
  }
}

@media screen and (min-width:811px) and (max-width: 998px) {
  .survey-form {
    .repeated-section {
      overflow: hidden;
      .vaccination-detail {
        h3 {
          font-size:25px;
        }
        .covide-infomation {
          font-size: 14px;
        }
        .background-image {
          position: absolute;
          height: 170px;
          right: 0px;
          top: 10px;
        }
      }
    }
  }
}