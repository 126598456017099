.survey-form {
  .intro-sec {
    padding: 30px;
    border-radius: 20px;
    background-color: #ffffff;
    p {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .que-section {
    margin-top: 40px;
  }
  .ans-sec {
    display: flex;
    margin: 20px 0;
  }
  .custom_radio input[type="radio"] {
    display: none;
  }
  .custom_radio input[type="radio"] + label {
    position: relative;
    display: inline-block;
    margin-right: 2em;
    cursor: pointer;
    line-height: 1em;
    padding: 12px 20px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #78aac3;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  .custom_radio input[type="radio"] + label:hover {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0 0 0px 2px #e0e0e0;
  }
  .custom_radio input[type="radio"]:checked + label {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #78aac3;
    color: #fff;
  }
}
.form-steps {
  width: 150px;
  display: flex;
  margin-left: auto;
  flex-direction: column;
  text-align: center;
  margin-top: 60px;
  max-height: 500px;
  span.number {
    background: #fff;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 2.2;
    margin: 0 auto;
    cursor: pointer;
  }
  .step {
    height: 33%;
    margin-top: 10px;
    p {
      font-size: 14px;
      font-weight: 600;
      padding: 5px 0 10px 0;
      margin: 0;
    }
    .step-border {
      height: 55%;
      width: 3px;
      background-color: #fff;
      margin: 0 auto;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
    }
    &.active {
      p {
        color: #f5a281;
      }
      .active-border {
        background-color: #78aac3;
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        width: 3px;
      }
    }
  }
  &.step1 {
    .active {
      .active-border {
        height: 50%;
      }
    }
  }
  &.step2 {
    .active {
      &:first-child {
        .active-border {
          height: 100%;
        }
      }
      .active-border {
        height: 50%;
      }
    }
  }
  &.step3 {
    .active {
      .active-border {
        height: 100%;
      }
    }
  }
}
