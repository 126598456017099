@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap");

/*----------------Utility classes-----------------*/
body {
  font-family: "Open Sans", sans-serif !important;
  color: #666666;
  background-color: #eff9ff !important;
  // font-size: 20px;
}
.container {
  margin: 0 auto;
  max-width: 1440px !important;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.flex-middle-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-middle {
  display: flex;
  justify-content: center;
}
.flex {
  display: flex;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.no-wrap {
  white-space: nowrap;
}
.opnSans {
  font-family: "Open Sans", sans-serif !important;
}
.Raleway {
  font-family: "Raleway", sans-serif !important;
}
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}

/*----------------Typography classes-----------------*/
h1,
.h1 {
  font-family: "Raleway", sans-serif !important;
  font-size: 48px;
  color: #78aac3 !important;
  font-weight: bold !important;
}
h2,
.h2 {
  font-family: "Raleway", sans-serif !important;
  font-size: 42px;
  color: #78aac3 !important;
  font-weight: bold !important;
}
h3,
.h3 {
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  color: #78aac3 !important;
  font-weight: bold !important;
}
p {
  font-size: 20px;
  // line-height: 1.75;
  color: #666666;
  margin: 0;
  font-weight: 600;
}
.font-36 {
  font-size: 36px;
}
.font-32 {
  font-size: 32px;
}
.font-18 {
  font-size: 18px;
}
.font-14 {
  font-size: 14px;
}

/*----------------forms classes-----------------*/

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"] {
  border-radius: 12px;
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  color: #666666;
  padding: 0 20px;
  height: 54px;
  width: 100%;
  margin-bottom: 25px;
  outline: none;
  border: 0;
}
::placeholder {
  font-weight: normal;
  font-size: 1rem;
}
.button {
  border-radius: 12px;
  font-size: 20px;
  padding: 15px 30px;
  &:disabled {
    opacity: 0.5;
  }
}
.btn-grey {
  background-color: #78aac3;
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
    padding: 10px 25px;
  }
  &.small {
    padding: 7px 30px;
    font-size: 18px;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.link {
  color: #f5a281;
  text-decoration: none;
  cursor: pointer;
  display: block;
  &:hover {
    color: #f5a281;
    text-decoration: underline;
  }
}
.blue-link {
  color: #3388ff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #3388ff;
    text-decoration: underline;
  }
}
/*----------------Comman classes-----------------*/
.bg-light-blue {
  background-color: #eff9ff;
}
.main-wrapper {
  height: 100%;
  overflow-x: hidden;
  position: relative;
}
.color-orange {
  color: #f5a281 !important;
}
.color-blue {
  color: #78aac3 !important;
}
.highlight {
  color: #f5a281 !important;
}
