.main-bg {
  background-color: #eff9ff;
  width: 100%;
  height: 100vh;
}

.login-row{
  display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px; */
    margin-left: -15px;
}

.my-otp{
  display: flex;
  width: 50%;
  margin-left: 5%;
}

.file-error{
  color: red;
  margin-left: 6%;
}

.input-fields2{
  margin-top: -10px;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #f5a281 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #f5a281;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #f5a281 ;
}
.image-upload-text{
  margin-left: 18%;
}

.image-upload-text2{
  margin-left: -3%;
  margin-top: 1%;
}
.image-upload-text3{
  margin-left: 22%;
  margin-top: 1%;
}

.locateme-view{
  position: sticky;
  margin-left: 76%;
  margin-top: -13%;
  cursor: pointer
}
.locateme-view1{
  position: sticky;
  margin-top: -6%;
  text-align: end;
  color: #666666b5;
}

.hero-title {
  /* margin-top: 6%;
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3; */
}

.hero-image {
  width: 80%;
  margin: 11% 10%;
  object-fit: contain;
}

/* .sign-up {
  margin-top: 12%;
} */

.stay-safe {
  margin-left: 6%;
}

.sign-up-title {
  margin-left: 6%;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78aac3;
}

.link-button {
  color: #f5a281;
  margin-top: -4px;
}

.input-fields {
  margin-left: 1%;
  margin-top: 3%;
}

.password-view1{
  position: sticky;
  margin-left: 92%;
  margin-top: -15%;
  cursor: pointer;
}

.text-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #666666;
}

.error {
  color: red;
}
.form-group .error {
  position: absolute;
  top: 16px;
  right: 20px;
}

.form-group {
  position: relative;
}
.form-inline .form-control {
  display: inline-block;
  vertical-align: center;
  width: auto;
}

.button-input {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 5%;
  color: #ffffff;
  background-color: #78aac3;
}

.recipient-tab1 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}
.recipient-tab2 {
  /* width: 26%; */
  /* height: 37px;
  flex-grow: 0;
  padding: 0px 12px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 2%; */
}

.selection {
  margin-left: 4%;
}

.small-avatar {
  right: calc(100% - 100%);
}

.selection-text {
  color: #666666;
  margin-left: 7%;
}

.radio-btn {
  /* width: 20px; */
  margin-right: 4%;
  background-color: #f5a281;
}

.captcha {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
}

.link-text {
  color: #3388ff;
  cursor: pointer;
}

.checkbox {
  margin-top: 6%;
  margin-left: 6%;
  font-size: 14px;
  color: #666666;
}

.pg-bar2 .progress-bar {
  background-color: #78aac3;
  color: white;
  height: 7px;
}

.pg-bar2 {
  height: 7px;
}

.pg-bar {
  margin-left: 4%;
}

.pg-title {
  padding-left: 0px;
}

.image-input-register {
  border-color: #ffffff;
  border-radius: 12px;
  margin: 15px;
  color: #666666;
  width: 100%;
  height: 220px;
  text-align: center;
  background-color: #ffffff;
  margin-left:5%;

}
.dropzone {
  height: 160px;
}

.dialog {
  width: 450px;
  height: 650px;
  margin-top: -100%;
  z-index: 10;
  border-color: transparent;
  border-radius: 5px;
}

.image {
  max-width: 400px;
  object-fit: cover;
  max-height: 600px;
}

.close-btn {
  float: right;
}


.error-parent {
  margin-top: 2%;
  margin-left: 6%;
  text-align: justify;
}

.custom-error {
  color: red;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .error-parent {
    margin: 0;
  }
  .image-preview-modal {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image-preview-modal .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border-radius: 100%;
    width: 30px;
  }
  .image-preview-modal .image {
    max-width: 100% !important;
  }
}